@tailwind base;
@tailwind components;
@tailwind utilities;

/* Auto Layout Vertical */

/* Auto layout */
.body {
  font-family: Poppins;
}
.main-cnt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
}
.sec-1 {
  width: 100vw;
  height: 832px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.56), rgba(0, 0, 0, 0.56)),
    url(/public/Assets/bg.jpeg);
  background-repeat: no-repeat; /* Prevents the background image from repeating */
  background-position: center; /* Centers the background image */
  background-size: cover; /* Ensures the background image covers the entire container */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 56px;
}

.form-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 40px 56px; */
  gap: 24px;
  /* width: 538px; */
  height: 536px;
  background: rgba(255, 255, 255, 0.88);
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  /* width: 426px; */
  height: 456px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.heading {
  font-family: Poppins;
  /* font-size: 40px; */
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #03032a;
}

/* Auto Layout Vertical */

.sec-2 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  background: #f3f3fc;
  border-radius: 24px;
}
.sec-2 {
  width: 100%;
  padding: 30px;
  max-width: 1385px;
}

@media (min-width: 768px) {
  .sec-2 {
    width: calc(100vw - 300px);
    padding: 72px 80px;
  }
}

.headingS2 {
  /* Header H4 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height, or 150% */
  letter-spacing: 0.25px;
  color: #03032a;
}
.txt-sb2 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
}

/* Auto Layout Vertical */

/* Auto layout */
.sec-3 {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 56px;
  /* width: 1440px; */
  /* height: 656px; */
  background: #ffffff;
  max-width: 1385px;
}
.headingS3 {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #03032a;
}

/* Auto Layout Horizontal */

.sec2-3-sub {
  /* display: flex;
  flex-direction: row; */
  align-items: center;
  padding: 0px;
  gap: 56px;
  /* width: 1216px; */
  /* height: 528px; */
}
/* Auto Layout Vertical */

.sec2-3-sub-1 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  /* gap: 24px; */
  /* width: 525px; */
  height: 528px;
}
.sec2-3-sub-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 0px; */
  gap: 48px;
  /* width: 635px; */
  /* height: 480px; */
}
/* Rectangle 4530 */

.pic1 {
  /* width: 100%; */
  height: 176px;
  background-image: url("/public/Assets/pic1.jpeg");
  background-color: #d9d9d9;
  border-radius: 7.27778px;
}
.pic2 {
  /* width: 100%; */
  height: 308px;
  background: url("/public/Assets/pic2.png"), #d9d9d9;
  border-radius: 7.27778px;
}
.pic3 {
  /* width: 100%; */
  height: 308px;
  background: url("/public/Assets/pic3.jpeg"), #d9d9d9;
  border-radius: 7.27778px;
}
.pic4 {
  /* width: 100%; */
  height: 176px;
  background: url("/public/Assets/pic4.jpeg"), #d9d9d9;
  border-radius: 7.27778px;
}

.sec2sub2-cnt {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  width: 100%;
  min-height: 200px;
  /* height: 144px; */
  background: #f5f5f5;
  border-radius: 8px;
}

.sec2sub2head {
  /* Header H6 */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */
  letter-spacing: 0.25px;
  color: #36393b;
}

.sec2sub2sub {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15000000596046448px;
  color: #6a6f6f;
}
.sec2sub2subPV {
  font-family: Raleway;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.15000000596046448px;
  color: #db7619;
  text-decoration: underline;
  cursor: pointer;
}

.sec-4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 72px 112px; */
  gap: 56px;
  /* width: 1440px; */
  /* height: 839px; */
  background: #f3f3fc;
}
.sec-4 {
  width: 100%;
  padding: 30px;
}

@media (min-width: 768px) {
  .sec-4 {
    padding: 72px 112px;
  }
}
.sec4headeing {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 0.25px;
  text-align: center;
}

.sec4sub {
  align-items: center;
  padding: 0px;
  gap: 56px;
  max-width: 1385px;
  /* width: 1216px; */
  /* height: 567px; */
}

.sec4sub1 {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  padding: 0px;
  gap: 48px;
  /* width: 524px; */
  /* height: 472px; */
}

.sec4sub2 {
  /* width: 636px; */
  height: 567px;
  display: flex;
  /* gap: 20px; */
}

.sec4sub1head {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.25px;
  color: #03032a;
}
.sec4sub1Cont {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #6a6f6f;
}
.pic5 {
  /* position: absolute; */
  /* width: 294px; */
  height: 400px;
  left: 610px;
  top: 77.18px;
  background: url("/public/Assets/pic5.jpeg");
  border-radius: 16px;
}

.pic6 {
  /* width: 188px; */
  height: 264px;
  background: url("/public/Assets/pic6.jpeg");
  border-radius: 16px;
}
.pic7 {
  /* width: 288px; */
  height: 279px;
  background: url("/public/Assets/pic7.jpeg");
  border-radius: 16px;
}

.sec-5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 0px 112px; */
  gap: 32px;
  /* width: 1440px;
  height: 322px; */
  background: #ffffff;
  opacity: 0.8;
  border-radius: 8px;
  max-width: 1385px;
}

.sec5heading {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #03032a;
}

.sec5partner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 180px;
  height: 85px;
  background: #f5f5f5;
  border-radius: 4px;
  color: #36393b;
  font-weight: 600;
}
.footer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 112px;
  gap: 24px;
  width: 100vw;
  height: 164px;
  background: #ffffff;
  border-top: 4px solid #36393b;
}

.ft-heading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.25px;
  text-align: center;
}
input#name,
input#email,
button#submit,
input#cd1,
input#cd2,
input#cd3,
input#cd4,
input#cd5,
input#cd6 {
  border-radius: 0px;
}
